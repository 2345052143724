<template>
  <div class="console">
    Latetudine: {{ newLocation.lat }} Longetudine: {{ newLocation.lng }}
    {{ newLocation }}
    <div v-for="p in myPrices" v-bind:key="p.id">{{ p }}</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Console",
  data() {
    return {
      patr: 1050,
      amount: 1
    };
  },
  methods: {
    ...mapActions(["initWebsocket", "getLocat"])
  },
  computed: {
    ...mapGetters(["myBtc", "myPrices", "newLocation"])
  },
  created() {
    this.getLocat();
    this.initWebsocket();
  }
};
</script>

<style scoped></style>
